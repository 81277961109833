<template> </template>

<script>
const { CognitoIdentityClient } = require('@aws-sdk/client-cognito-identity')
const {
  fromCognitoIdentityPool
} = require('@aws-sdk/credential-provider-cognito-identity')
const { LambdaClient, InvokeCommand } = require('@aws-sdk/client-lambda')

export default {
  methods: {
    async discourseLogout() {
      const REGION = 'us-west-2'
      let cognitoProviderName = "cognito-idp." + REGION + ".amazonaws.com/" + process.env.VUE_APP_COGNITO_POOL_ID
      const lambda = new LambdaClient({
        region: REGION,
        credentials: fromCognitoIdentityPool({
          client: new CognitoIdentityClient({ region: REGION }),
          identityPoolId: process.env.VUE_APP_COGNITO_IDENTITY_POOL_ID_LAMBDA,
          logins: {
            [`${cognitoProviderName}`]: this.$store.state.user.session.idToken.jwtToken
          }
        })
      })
      const params = {
        FunctionName: 'GetUserID',
        InvocationType: 'RequestResponse',
        LogType: 'None',
        Payload: JSON.stringify({
          options: {
            hostname: 'discourse.bio-conversion.org',
            port: '443',
            path: `/u/by-external/${this.$store.state.user.userAttributes['cognito:username']}.json`,
            method: 'GET',
            headers: {
              'Content-Type': 'multipart/form-data',
              'Api-Key': process.env.VUE_APP_DISCOURSE_LOGOUT_SECRET,
              'Api-Username': 'system'
            }
          }
        })
      }
      try {
        const data = await lambda.send(new InvokeCommand(params))
        console.log('Discourse logout activated', data)
      } catch (err) {
        console.log('Error', err)
      }
    }
  },
  created() {
    this.discourseLogout()
    this.$store.dispatch('logout')
    this.$router.push('/')
  }
}
</script>
